import React from 'react'

import { Icon } from './styles'
import {
  Props, Theme,
} from './types'

const ButtonIcon: React.FC<Props> = ({
  theme = Theme.default,
  ...props
}): React.ReactElement => <Icon theme={theme} {...props} />

export * from './types'

export default ButtonIcon
