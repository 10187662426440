export enum Theme {
  regular = 'regular',
  scan = 'scan',
  edit = 'edit',
  change = 'change',
  link = 'link',
  yellow = 'yellow',
  diamond = 'diamond',
}

type Type = 'button' | 'submit'

export interface ContainerProps extends Omit<Props, 'type'> {
  isQuoteRequestNext?: boolean,
  onClick?: Function,
  type?: Type,
}

export interface Props {
  autoFocus?: boolean,
  center?: boolean,
  disabled?: boolean,
  iconText?: string,
  large?: boolean,
  secondary?: boolean,
  theme?: Theme,
  type?: Type,
  wide?: boolean,
  wider?: boolean,
}

export interface StyleProps {
  change: boolean,
  edit: boolean,
}
