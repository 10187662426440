import React from 'react'

import Title from 'components/base/Title'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'

import Modal from 'components/blocks/Modal'

import { Props } from './types'

const SessionTimeout: React.FC<Props> = ({
  secondsUntilExpiration,
  modalWarning,
  onContinue,
  onReset,
}): React.ReactElement => (
  <>
    <Modal
      isOpen={modalWarning.isOpen}
      label='Clear Information?'
      buttons={[
        {
          center: true,
          label: 'Forget My Info',
          onClick: onReset,
          wide: true,
        },
        {
          center: true,
          label: 'Continue',
          onClick: onContinue,
          secondary: true,
          wide: true,
        },
      ]}
    >
      <Title level={3} noMargin={true}>
        Continue Session?
      </Title>
      <Paragraph theme={ParagraphTheme.intro}>Your session will expire and your data will be forgotten in {secondsUntilExpiration} seconds.</Paragraph>
      <Paragraph theme={ParagraphTheme.intro}>Click Continue to avoid losing your data.</Paragraph>
    </Modal>
  </>
)

export default SessionTimeout
