import React from 'react'

import { Row } from './styles'

import {
  Props,
  Theme,
} from './types'

const ButtonRow: React.FC<Props> = ({
  theme = Theme.regular,
  ...props
}): React.ReactElement => <Row theme={theme} {...props} />

export * from './types'

export default ButtonRow
