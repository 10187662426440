import styled from 'styled-components'

import Label from 'components/base/Form/Label/styles'

// eslint-disable-next-line import/prefer-default-export
export const Content = styled.div`
  padding: 0px 20px 40px;
  text-align: center;
  ${Label} {
    text-align: left;
  }
`
