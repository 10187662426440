import React from 'react'

import Paragraph from 'components/base/Paragraph'
import Link, { Theme as LinkTheme } from 'components/base/Link'
import ButtonRow from 'components/base/Button/Row'
import {
  AccidentIcon,
  TowTruckIcon,
} from 'components/base/Icon/styles'

import {
  ErrorDesc,
  ErrorTitle,
  ErrorType,
} from './styles'

import { Props } from './types'

const ErrorPageContent: React.FC<Props> = ({
  code = 500,
  description,
  showLink = false,
  title = 'Just an accident',
}): React.ReactElement => (
  <div>
    <Paragraph>
      {(code === 503 && <TowTruckIcon />) || <AccidentIcon />}
    </Paragraph>
    <ErrorTitle>{title}</ErrorTitle>
    {((description || code === 500) && (
      <ErrorDesc>{description || '(at least no one was hurt)'}</ErrorDesc>
    )) ||
      undefined}
    <ErrorType>{code}</ErrorType>
    {(showLink && (
      <ButtonRow>
        <Link theme={LinkTheme.button} to='/'>
          Return Home
        </Link>
      </ButtonRow>
    )) ||
      undefined}
  </div>
)

export default ErrorPageContent
