import React from 'react'

import { Props } from './types'

import {
  FirstDiv,
  SecondDiv,
  Wrapper,
} from './styles'

const Loading: React.FC<Props> = ({
  theme,
  ...props
}): React.ReactElement => (
  <Wrapper theme={theme} {...props}>
    <FirstDiv />
    <SecondDiv />
  </Wrapper>
)

export * from './types'

export default Loading
