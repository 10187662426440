import React from 'react'

import Button, { Theme as ButtonTheme } from 'components/base/Button'

import ImageBackground from 'components/blocks/ImageBackground'
import ErrorPageContent from 'components/blocks/ErrorPageContent'

interface Props {
  hasEnteredQuoteInfo: boolean,
  onForgetMeOpen: Function,
}

const Http503: React.FC<Props> = ({
  hasEnteredQuoteInfo,
  onForgetMeOpen,
}): React.ReactElement => (
  <ImageBackground>
    <ErrorPageContent
      code={503}
      description={(
        <>
          <p>We are undergoing routine maintenance, be right back.</p>
          {hasEnteredQuoteInfo && (
            <>
              <p>Your information has been saved.</p>
              <p>If you would like to abandon your session, you may <Button theme={ButtonTheme.link} onClick={onForgetMeOpen}>clear your information</Button> at any time.</p>
            </>
          )}
        </>
      )}
      showLink={false}
      title='Maintenance Mode'
    />
  </ImageBackground>
)

export default Http503
