import styled from 'styled-components'

import BGRIMAGE from 'assets/images/backgrounds/bgr-404.webp'

export const ImageWrap = styled.div`
  background-position: top center;
  background-attachment: fixed;
  background-image: url(${BGRIMAGE});
  background-size: cover;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`

export const ImageWrapContent = styled.div`
  margin: auto;
  padding: 20px;
  width: 100%;
  text-align: center;
`
