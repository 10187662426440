export enum Theme {
  regular = 'regular',
  stacked = 'stacked',
}

export interface Props {
  isLeftALigned?: boolean,
  borderTop?: boolean,
  theme?: Theme,
  section?: boolean,
}
