
import styled from 'styled-components'

import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'


import Concierge from 'assets/images/icons/Concierge.svg'

export const ConciergeIconWrap = styled.span`
  display: inline-block;
  position: relative;
  height: 153px;
  width: 260px;
  background-image: url(${Concierge});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  ${MEDIA.medium`
    height: 176px;
    width: 300px;
  `}
`

export const ConciergeIconContent = styled.span`
  font-size: ${FONTS.PARAGRAPHMOBILE};
  font-weight: 600;
  position: absolute;
  right: 20px;
  top: 16px;
  width: 104px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${MEDIA.medium`
    right: 30px;
    top: 25px;
    font-size: ${FONTS.PARAGRAPHLARGE};
  `}
`
