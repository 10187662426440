import styled from 'styled-components'

import COLORS from 'constants/colors'

import FONTS from 'constants/fonts'

import MEDIA from 'constants/media'


import License from 'assets/images/icons/License.svg'
import Pencil from 'assets/images/icons/Pencil.svg'
import BinocularsWhite from 'assets/images/icons/BinocularsWhite.svg'


import { StyleProps } from './types'

export const ButtonWrap = styled.span`
  ${({ theme }): string =>
    theme === 'link'
      ? 'display: inline-block;'
      : 'block'}
`

export const DiamondButtonWrap = styled.div`
  padding: 5px 0 20px 0;
  position: relative;
  display: inline-block;
  ${MEDIA.medium`
    padding: 5px 0 0 0;
  `}
`

export const regular = styled.button<StyleProps>`
  background: ${COLORS.BLUE};
  border-radius: 4px;
  border: none;
  color: ${COLORS.WHITE};
  cursor: pointer;
  display: inline-block;
  font-size: ${FONTS.FONTBUTTONMOBILE};
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};
  padding: 15px 30px;
  text-decoration: none;
  text-align: center;
  will-change: background-color;
  transition: background-color 100ms linear;
  width: auto;
  max-width: auto;
  margin: 0 0;
  &.center {
    margin: 0 auto;
  }
  &.wide {
    width: 100%;
    max-width: 360px;
  }
  &.wider {
    width: 100%;
    max-width: 480px;
  }
  &.secondary {
    background: ${COLORS.LIGHTBLUE};
  }
  ${({ edit }): string =>
    edit
      ? `background: ${COLORS.LIGHTBLUE} url(${Pencil})no-repeat left 35px center; background-size:40px auto;padding-left: 60px; width:100%; max-width:360px;`
      : ''}
  ${({ change }): string =>
    change
      ? `background: ${COLORS.LIGHTBLUE} url(${BinocularsWhite})no-repeat left 35px center; background-size:40px auto;padding-left: 60px; width:100%; max-width:360px;`
      : ''}
  ${({ disabled }): string | undefined =>
    (disabled &&
      `
      background: ${COLORS.GREYMEDIUM};
      cursor: default;
      cursor: not-allowed;
    `) ||
    undefined}
  a:link,
  a:visited,
  a:hover {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
  ${MEDIA.medium`
    font-size: ${FONTS.FONTBUTTON};
  `}
`

export const diamond = regular

export const scan = styled.button`
  background: ${COLORS.BLUE} url(${License}) no-repeat center top 20px;
  border-radius: 4px;
  border: none;
  color: ${COLORS.WHITE};
  cursor: pointer;
  display: inline-block;
  font-size: 29px;
  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTREGULAR};
  padding: 90px 20px 20px 20px;
  text-decoration: none;
  width: 100%;
  &.wide {
    max-width: 360px;
  }
  strong {
    font-weight: ${FONTS.WEIGHTDEMI};
  }
  ${MEDIA.medium`
    background: ${COLORS.BLUE} url(${License}) no-repeat left 20px center;
    padding: 20px 20px 20px 125px;
  `}
`

export const edit = styled(regular)`
  background: ${COLORS.LIGHTBLUE};
  width: 100%;
  max-width: 360px;
  ${MEDIA.medium`
    font-size: ${FONTS.FONTBUTTON};
    padding-left: 60px;
    background: ${COLORS.LIGHTBLUE} url(${Pencil})no-repeat left 35px center;
    background-size:40px auto;
  `}
`

export const change = styled(regular)`
  background: ${COLORS.LIGHTBLUE};
  width: 100%;
  max-width: 360px;
  ${MEDIA.medium`
    font-size: ${FONTS.FONTBUTTON};
    padding-left: 60px;
    background: ${COLORS.LIGHTBLUE} url(${BinocularsWhite})no-repeat left 35px center;
    background-size:40px auto;
  `}
`

export const link = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  background-color: transparent;

  color: ${COLORS.BLACK};
  text-decoration: underline;

  &:link,
  &:visited {
    color: ${COLORS.BLACK};
  }

  &:hover {
    text-decoration: none;
  }
`

export const IconText = styled.span`
  color: ${COLORS.GOLD};
  font-family: ${FONTS.FAMILYSECONDARY};
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  line-height: 1em;
  font-weight: 600;
  font-size: 18px;
  font-style: italic;
  ${MEDIA.medium`
    width: 40px;
    right: -50px;
    left: auto;
    top: 55%;
    bottom: auto;
    transform: translateY(-50%);
  `}
`
