import React from 'react'

import {
  ImageWrap,
  ImageWrapContent,
} from './styles'

const ImageBackground: React.FC = (props): React.ReactElement => (
  <ImageWrap>
    <ImageWrapContent>{props.children}</ImageWrapContent>
  </ImageWrap>
)

export default ImageBackground
