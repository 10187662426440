import React from 'react'
import ReactModal from 'react-modal'

import ButtonRow, { Theme as ButtonRowTheme } from 'components/base/Button/Row'
import Button from 'components/base/Button'
import Link, { Theme as LinkTheme } from 'components/base/Link'
import Loading from 'components/base/Loading'

import ConciergeIcon from 'components/blocks/ConciergeIcon'

import { Content } from './styles'

import {
  ButtonProps,
  LinkProps,
  Props,
} from './types'

const Modal: React.FC<Props> = ({
  buttons = [],
  children,
  isOpen,
  label = '',
  maxWidth = '640px',
  needHelp = false,
  onRequestClose,
}): React.ReactElement => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: maxWidth,
      },
    }}
    contentLabel={label}
    overlayClassName='Overlay'
  >
    <Content>
      {(typeof children === 'function' && children()) || children}
      <ButtonRow
        section={true}
        theme={buttons && buttons.length > 1 ? ButtonRowTheme.stacked : ButtonRowTheme.regular}
      >
        {buttons &&
          buttons.filter(button => button).map(button => {
            if (!button) {
              return undefined
            }

            const {
              isLoading,
              label,
              theme,
              ...buttonProps
            } = button

            if (isLoading) {
              return <Loading key={label} />
            }

            if (theme === 'link') {
              return (
                <Link key={label} {...buttonProps as LinkProps} theme={LinkTheme.button}>
                  {label}
                </Link>
              )
            }

            return (
              <Button key={label} theme={theme} {...buttonProps as ButtonProps}>
                {label}
              </Button>
            )
          })}
      </ButtonRow>
      {needHelp && (
        <React.Fragment>
          <ConciergeIcon title='Need help?' />
          <ButtonRow>
            <Link center={true} secondary={true} theme={LinkTheme.button} to='/support'>
              Send to an Insurance Concierge
            </Link>
          </ButtonRow>
        </React.Fragment>
      )}
    </Content>
  </ReactModal>
)

export * from './types'

export default Modal
