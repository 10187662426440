
import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'


export const ErrorTitle = styled.div`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.FONTTITLE};
`

export const ErrorDesc = styled.div`
  * {
    color: ${COLORS.BLUE};
  }
  font-size: ${FONTS.FONTXXLARGE};
`

export const ErrorType = styled.div`
  color: ${COLORS.BLUE};
  font-size: ${FONTS.FONTTITLELARGE};
  font-weight: ${FONTS.WEIGHTBOLD};
  margin-top: 40px;
`
