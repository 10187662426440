import styled from 'styled-components'

import ButtonDiamond from 'assets/images/icons/GoldDiamond.svg'

/* eslint-disable import/prefer-default-export */
export const Icon = styled.span`
  display: inline-block;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-top: 10px;

  ${(props): string | undefined => (props.theme === 'diamond' && `
    background-image: url(${ButtonDiamond});
    background-size: cover;
    position: absolute;
    right: -15px;
    top: -15px;
  `) || undefined}
`
