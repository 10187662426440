import React from 'react'
import { FormSpy } from 'react-final-form'

import { ContainerProps } from './types'

import Button from './index.presentational'

const ButtonContainer: React.FC<ContainerProps> = ({
  disabled = false,
  isQuoteRequestNext = false,
  ...props
}): React.ReactElement => {
  if (isQuoteRequestNext) {
    return React.createElement(
      FormSpy,
      { subscription: { valid: true } },
      () => React.createElement(Button, {
        ...props,
        disabled,
      }),
    )
  }

  return React.createElement(Button, {
    ...props,
    disabled,
  })
}

export * from './types'

export default ButtonContainer
