import React from 'react'

import {
  ConciergeIconWrap,
  ConciergeIconContent,
} from './styles'

import { Props } from './types'

const ConciergeIcon: React.FC<Props> = ({
  title,
  ...props
}): React.ReactElement => (
  <ConciergeIconWrap {...props}>
    <ConciergeIconContent>{title}</ConciergeIconContent>
  </ConciergeIconWrap>
)

export default ConciergeIcon
