
import styled from 'styled-components'

import COLORS from 'constants/colors'
import MEDIA from 'constants/media'


import { regular as buttonRegular } from 'components/base/Button/styles'

import { StyledInternalLinkThatLooksLikeButton as LinkButton } from 'components/base/Link/styles'

import { Props } from './types'

const stackedCss = `
  margin: 10px auto;
  display: block;
`

/* eslint-disable import/prefer-default-export */
export const Row = styled.div`
  border-top: ${(props: Props): string =>
    props.borderTop ? `2px solid ${COLORS.BORDERS}` : 'none'};
  padding-top: ${(props: Props): string => (props.borderTop ? '10px' : '0')};
  width: 100%;
  text-align: ${(props: Props): string => (props.isLeftALigned ? 'left' : 'center')};
  margin: ${(props: Props): string => (props.section ? '20px 0' : '20px 0 0 0')};
  ${buttonRegular} {
    margin: 10px 0;
  }
  ${LinkButton} {
    margin: 10px;
  }
  ${MEDIA.medium`
    margin: ${(props: Props): string => (props.section ? '40px 0' : '40px 0 0 0')};
  `}

  ${(props: Props): string | undefined => (props.theme === 'stacked' && `
    ${buttonRegular} {
      ${stackedCss}
    }
    ${LinkButton} {
      ${stackedCss}
    }
    ${LinkButton}.center {
      ${stackedCss}
    }
  `) || undefined}
`
