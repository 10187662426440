import styled, { keyframes } from 'styled-components'

import COLORS from 'constants/colors'


import { Props } from './types'

/* eslint-disable import/prefer-default-export */
export const Wrapper = styled.div<Props>`
  display: block;
  position: relative;
  width: 64px;
  height: 84px;
  margin: auto;

  ${(props: Props): string | undefined => (props.theme === 'rates' && `
    display: inline-block;
    width: 64px;
    height: 64px;
    vertical-align: middle;
    div {
      border-color: ${COLORS.LIGHTBLUE}
    }
  `) || undefined}
`

const ripple = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`

const Div = styled.div`
  position: absolute;
  border: 4px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  margin: auto;
`

export const FirstDiv = styled(Div)``

export const SecondDiv = styled(Div)`
  animation-delay: -0.5s;
`
