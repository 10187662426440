import React from 'react'
import classnames from 'classnames'

import * as styles from './styles'

import ButtonIcon from './Icon'
import { Theme as IconTheme } from './Icon/types'
import {
  Props,
  Theme,
} from './types'

const Button: React.FC<Props> = ({
  center = false,
  large = false,
  secondary = false,
  theme = Theme.regular,
  wide = false,
  wider = false,
  iconText = undefined,
  ...props
}): React.ReactElement => {
  const {
    ButtonWrap,
    DiamondButtonWrap,
    IconText,
  } = styles

  const ThemedButtonWrap = (theme === Theme.diamond && DiamondButtonWrap) || ButtonWrap
  const ThemedButton = styles[theme]

  return (
    <ThemedButtonWrap theme={theme}>
      <ThemedButton
        {...props}
        className={classnames({
          center,
          large,
          secondary,
          wide,
          wider,
        })}
      />
      {theme === Theme.diamond && (
      <>
        <ButtonIcon theme={IconTheme[theme]} />
        {(iconText && <IconText>{iconText}</IconText>) || undefined}
      </>
      )}
    </ThemedButtonWrap>
  )
}

export default Button
