import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { isQuoteRequestPristine } from 'state/quotes/selectors'

import { ForgetMeContext } from 'components/blocks/Modal/ForgetMe/context'

import Http503 from './index.presentational'

const Http503Container: React.FC = (): React.ReactElement => {
  const { setIsOpen: setIsForgetMeOpen } = useContext(ForgetMeContext)

  const isPristine = useSelector(isQuoteRequestPristine)

  return React.createElement(Http503, {
    hasEnteredQuoteInfo: !isPristine,
    onForgetMeOpen: () => {
      setIsForgetMeOpen(true)
    },
  })
}

export default Http503Container
