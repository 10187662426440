
import styled from 'styled-components'

import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'


import { Props } from './types'

export default styled.label`
  display: block;
  font-family: ${FONTS.FAMILYSECONDARY};
  font-size: ${FONTS.FONTLABELMOBILE};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin-bottom: 5px;
  text-align: ${(props: Props): string => (props.center ? 'center' : 'inherit')};
  ${MEDIA.medium`
    font-size: ${FONTS.FONTLABEL};
  `}
  span {
    display: block;
    font-size: ${FONTS.PARAGRAPHSMALL};
    font-family: ${FONTS.FAMILYPRIMARY};
    font-weight: ${FONTS.WEIGHTREGULAR};
  }
`
